import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
import {isEqual} from 'underscore'
import {findOptions, roundNumber, hmsToSeconds} from '@/function'

import filterWSByProject from '@/mixins/filterWSByProject.js'
import updateUrl from '@/mixins/updateUrl'
import regionFilter from '@/mixins/regionFilter'

import filters from './components/filters'
import comparisonTable from './components/comparisonTable'
import segmentMetricChart from '../segmentMetricChart'
import error from '@/services/errors/block'
import container from '@/services/components/container/'

import CONSTANTS from '@/const/'

const PERIOD_COUNT = ['one', 'two']
const TRAFFIC_FILTER_TYPES = ['source', 'medium']

export default {
    name: 'comparisonContainer',

    metaInfo () {
        return {
            title: `${this.title.primary} / ${this.title.secondary} / ${this.activeProjectData.label} (${this.activeProjectData.id})`
        }
    },

    mixins: [
      updateUrl,
      filterWSByProject,
      regionFilter
    ],

    components: {
        'v-filters': filters,
        'v-comparison-table': comparisonTable,
        'v-segment-metric-chart': segmentMetricChart,
        'v-error': error,
        'v-container': container
    },

    props: {
        title: {
            type: Object,
            default: () => ({})
        },
        segmentFilterUrl: {
            type: String,
            default: ''
        },
        requestDataFunc: {
            type: Function,
            required: true
        },
        catalog: {
            type: Object,
            required: true
        },
        segmentTreeCatalog: {
            type: Object,
            required: true
        },
        summary: {
            type: Object,
            required: true
        },
        customSegments: {
            type: Boolean,
            default: false
        },
        saveCatalogFunc: {
            type: Function,
            required: true
        }
    },

    data () {
        return {
            preloader: {
                table: true,
                filters: true,
                segments: true
            },

            selectedItems: [],

            showChart: true,

            searchEngines: [
                {id: CONSTANTS.SEARCH_SYSTEM.GOOGLE, name: 'Google'},
                {id: CONSTANTS.SEARCH_SYSTEM.YANDEX, name: 'Яндекс'}
            ]
        }
    },

    computed: {
        ...mapState({
          activeProjectData: state => state.active_project_data,
          tableCellsConfig: state => state.tableSettings.catalogTableCellsConfig,
          trafficConditions: state => state.trafficConditions,
          trafficSources: state => state.trafficSources
        }),

        ...mapGetters(['filtersDevices']),

        viewPage () {
            return this.activeProjectData && Object.keys(this.activeProjectData).length !== 0
        },

        /* Фильтры в URL */
        urlFilters () {
            let queryParams = {...this.$route.query}
            let filters = {
                date: {
                    one: {
                        from: undefined,
                        to: undefined
                    },
                    two: {
                        from: undefined,
                        to: undefined
                    }
                }
            }

            /* Инициализация URL фильтров */
            const initFilters = (query, options, required = false, defaultOption, cycle, optionKey = 'id', number = false, string = false) => {
                try {
                    if (cycle) {
                        if (query) {
                            /* Проверка наличия опций в фильтрах */
                            if (query && Array.isArray(query)) {
                                return query.map(f => number ? +f : f).filter(f => f !== null && f !== undefined && ((!string && !isNaN(f)) || string))
                            } else if (typeof query === 'string' && number && !isNaN(+query)) {
                                return [+query]
                            } else if ((typeof query === 'number' && !isNaN(+query)) || typeof query === 'string' && !number) {
                                return [query]
                            } else {
                                return defaultOption || []
                            }
                        } else {
                            return defaultOption || []
                        }
                    } else {
                        if (typeof query === 'object' && options) {
                            for (let item of options) {
                                if (query.source && query.source === item.source && query.medium && query.medium === item.medium) {
                                    return item.id
                                }
                            }
                        } else {
                            if ((query || query === 0) && findOptions(query, options, optionKey)) {
                                return query
                            } else if (required) {
                                return defaultOption || options[0].id
                            } else {
                                return null
                            }
                        }
                    }
                } catch (_) {
                    return null
                }
            }

            filters.all_site = queryParams.all_site ? queryParams.all_site === 'true' : true

            filters.control_group = initFilters(queryParams.control_group, this.segmentTreeCatalog.items)

            filters[this.segmentFilterName] = initFilters(queryParams[this.segmentFilterName], this.segmentTreeCatalog.items, false, this.catalog.items
                .slice(0, 5)
                .filter(item => filters.all_site || !filters.all_site && !filters.control_group || !filters.all_site && filters.control_group && String(filters.control_group) !== String(item[this.customSegments ? 'name_hash' : 'catalog_id']))
                .map(item => String(item[this.customSegments ? 'name_hash' : 'catalog_id'])), true).filter(id => !isNaN(+id))

            filters.metric = initFilters(queryParams.metric, this.metrics, true, 'count_all')

            filters.is_mobile = initFilters(+queryParams.is_mobile, this.devices)

            filters.order_by = queryParams.order_by || 'one'

            filters.order_direction = queryParams.order_direction || 'DESC'

            filters.search_engine = initFilters(queryParams.search_engine, this.searchEngines)

            filters.region_id = initFilters(queryParams.region_id, this.regions, false, undefined, true, 'google_region_id', true)

          filters.traffic_source = initFilters(queryParams.traffic_source, this.trafficSources, true, 'ga4')

            for (let type of TRAFFIC_FILTER_TYPES) {
                filters[type] = initFilters(queryParams[type], undefined, false, [], true, undefined, false, true)
                filters[`traffic_conditions[${type}]`] = initFilters(queryParams[`traffic_conditions[${type}]`], this.trafficConditions)
            }

            for (let count of PERIOD_COUNT) {
                if (queryParams[`period_${count}_date_from`] && queryParams[`period_${count}_date_to`]) {
                    let dateFrom = +this.$moment.utc(queryParams[`period_${count}_date_from`], CONSTANTS.DATE_FORMAT.BASE_FORMAT).format('x')
                    let dateTo = +this.$moment.utc(queryParams[`period_${count}_date_to`], CONSTANTS.DATE_FORMAT.BASE_FORMAT).format('x')

                    filters.date[count] = {
                        from: dateFrom || dateTo,
                        to: dateTo || dateFrom
                    }
                } else {
                    if (queryParams[`period_${count}_date_to`]) {
                        let dateTo = +this.$moment.utc(queryParams[`period_${count}_date_to`], CONSTANTS.DATE_FORMAT.BASE_FORMAT).format('x')

                        filters.date[count] = {
                            from: +this.$moment.utc(dateTo, 'x').subtract(7, 'days').format('x'),
                            to: dateTo
                        }
                    } else if (queryParams[`period_${count}_date_from`]) {
                        let dateFrom = +this.$moment.utc(queryParams[`period_${count}_date_from`], CONSTANTS.DATE_FORMAT.BASE_FORMAT).format('x')
                        let dateTo = +this.$moment.utc(dateFrom, 'x').add(7, 'days').format('x')
                        filters.date[count].from = dateFrom

                        if (dateTo > +this.$moment.utc().endOf('day').format('x')) {
                            filters.date[count].to = +this.$moment.utc().startOf('day').format('x')
                        } else {
                            filters.date[count].to = dateTo
                        }
                    } else if (count === 'one') {
                        let dateDefault = +this.$moment.utc().subtract(1, 'days').startOf('day').format('x')
                        filters.date[count] = {
                            from: +this.$moment.utc(dateDefault, 'x').subtract(7, 'days').format('x'),
                            to: dateDefault
                        }
                    } else if (count === 'two') {
                        let dateDefault = +this.$moment.utc().subtract(9, 'days').startOf('day').format('x')
                        filters.date[count] = {
                            from: +this.$moment.utc(dateDefault, 'x').subtract(7, 'days').format('x'),
                            to: dateDefault
                        }
                    }
                }
            }

            return filters
        },

        dateFilters () {
            let dateFilters = {
                one: {
                    from: undefined,
                    to: undefined
                },
                two: {
                    from: undefined,
                    to: undefined
                }
            }

            const urlFiltersDate = {...this.urlFilters.date}

            for (let count of PERIOD_COUNT) {
                try {
                    if (urlFiltersDate[count] && urlFiltersDate[count].from) {
                        dateFilters[count].from = this.$moment(urlFiltersDate[count].from).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT)
                    }

                    if (urlFiltersDate[count] && urlFiltersDate[count].to) {
                        dateFilters[count].to = this.$moment(urlFiltersDate[count].to).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT)
                    }
                } catch (_) {
                }
            }

            return {
                ...dateFilters,
                comparison: !!dateFilters.two.from && !!dateFilters.two.to && (dateFilters.one.from !== dateFilters.two.from || dateFilters.one.to !== dateFilters.two.to)
            }
        },

        /* Список метрик */
        metrics () {
            let metrics = []

            let config = this.filteredTableCellsConfig || this.tableCellsConfig
            config.forEach(section => {
                metrics.push({...section, parent_id: '0'})
                section.items.forEach(metric => {
                    metrics.push({...metric, parent_id: section.id})
                })
            })

            return metrics
        },

        activeMetric () {
            try {
                let metric

                metric = this.metrics.find(item => item.id === this.urlFilters.metric)

                return metric
            } catch (_) {
                return undefined
            }
        },

        dateFiltersFormatted() {
            const {one, two} = this.dateFilters

            return {
                one: {
                    from: this.$moment(one.from, CONSTANTS.DATE_FORMAT.BASE_FORMAT).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT_REVERSE_DOT),
                    to: this.$moment(one.to, CONSTANTS.DATE_FORMAT.BASE_FORMAT).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT_REVERSE_DOT)
                },
                two: {
                    from: this.$moment(two.from, CONSTANTS.DATE_FORMAT.BASE_FORMAT).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT_REVERSE_DOT),
                    to: this.$moment(two.to, CONSTANTS.DATE_FORMAT.BASE_FORMAT).format(CONSTANTS.DATE_FORMAT.BASE_FORMAT_REVERSE_DOT)
                }
            }
        },

        controlGroup () {
            let item
            if (this.urlFilters.all_site) {
                item = {
                    ...this.summary.data,
                    url_title: 'Весь сайт',
                    catalog_id: 0
                }
            } else if (this.catalog.items.length && Array.isArray(this.catalog.items) && this.urlFilters.control_group) {
                item = this.catalog.items.find(item => String(item[this.customSegments ? 'name_hash' : 'catalog_id']) === String(this.urlFilters.control_group))
            }

            if (item) {
                let newItem = {
                    url_title: item.url_title || item.name,
                    one: item[`${this.activeMetric.id}_one`],
                    two: item[`${this.activeMetric.id}_two`],
                    diff: item[`${this.activeMetric.id}_diff`],
                    diff_prc: this.getDiffPrc(item)
                }
                if (this.customSegments) {
                    newItem.catalog_id = item.name_hash
                } else {
                    [
                        'catalog_id',
                        'url_name',
                        'level',
                        'listingPage',
                        'type_id',
                        'count_elements',
                        'is_listing'
                    ].forEach(key => newItem[key] = item[key])}
                return newItem
            } else {
                return undefined
            }
        },

        segmentFilterName () {
            return this.customSegments ? 'segments' : 'catalog_ids'
        },

        devices() {
            return this.filtersDevices
        },

        regionSelected() {
            return this.getRegionSelected(this.urlFilters.region_id)
        },

        filtersLoaded () {
            return !this.preloader.filters && !this.preloader.segments
        }
    },

    watch: {
        async $route (to, from) {
            const qt = to.query
            const qf = from.query

            if (
                qt.metric !== qf.metric ||
                qt.order_by !== qf.order_by ||
                qt.order_direction !== qf.order_direction ||
                (qt.all_site === 'false' && qt.all_site !== qf.all_site) ||
                (qt.all_site === 'true' && this.summary.has_data && qt.all_site !== qf.all_site) ||
                (qt.control_group !== qf.control_group && this.urlFilters[this.segmentFilterName].includes(qt.control_group)) ||
                (!qt.control_group && qt.control_group !== qf.control_group)
            ) {
                return
            } else if (!isEqual(qt[this.segmentFilterName], qf[this.segmentFilterName]) && qt[this.segmentFilterName] && !qt[this.segmentFilterName].length) {
                this.saveCatalogFunc({
                    hasData: false,
                    catalogId: '0'
                })
                this.selectedItems = []
                return
            }

            if (this.filtersLoaded) {
                this.setCancelRequests(true)
                await this.init()
            }
        },

        async filtersLoaded (val) {
            if (val) {
                await this.init({
                    order_direction: 'DESC',
                    order_by: `${this.activeMetric.filterName}_one`,
                    limit: !this.urlFilters[this.segmentFilterName].length ? 5 : undefined
                })
            }
        }
    },

    async created () {
        this.saveCatalogFunc({
            hasData: false,
            catalogId: '0'
        })

        try {
            await this.getFilters({
                query: {
                    project_id: this.activeProjectId,
                    skip_regionality: 1
                }
            })
        } catch ({message}) {
            this.$notify({title: message, type: CONSTANTS.NOTIFY_STATUS.ERROR})
        } finally {
            this.preloader.filters = false
        }

        await this.getRegions()
    },

    destroyed () {
        this.setCancelRequests(true)
    },

    methods: {
        ...mapActions(['getFilters']),

        ...mapMutations({
            setCancelRequests: 'setCancelRequests'
        }),

        setItems () {
            this.selectedItems = []
            this.urlFilters[this.segmentFilterName].forEach(id => {
                let item
                for (let catalogItem of this.catalog.items) {
                    if (String(id) === String(catalogItem[this.customSegments ? 'name_hash' : 'catalog_id'])) {
                        item = catalogItem
                        break
                    }
                }

                if (!item) {
                    const catalogItem = findOptions(id, this.segmentTreeCatalog.items)

                    item = findOptions(+catalogItem.parent_id, this.catalog.items, this.customSegments ? 'name_hash' : 'catalog_id')
                }

                if (item && !findOptions(item[this.customSegments ? 'name_hash' : 'catalog_id'], this.selectedItems, this.catalog.items, this.customSegments ? 'name_hash' : 'catalog_id')) {
                    this.selectedItems.push(item)
                }
            })
        },

        finishFiltersLoading () {
            this.$set(this.preloader, 'segments', false)
        },

        /* Инициализация данных */
        async init (filters) {
            this.$set(this.preloader, 'table', true)
            try {
                if (this.urlFilters.all_site) {
                    this.requestData({}, 1)
                }
                await this.requestData(filters)
                    .then(() => this.setItems())
            } catch ({message}) {
                this.$notify({ type: CONSTANTS.NOTIFY_STATUS.ERROR, title: message })
            } finally {
                this.$set(this.preloader, 'table', false)
            }
        },

        /* Загрузка данных */
        async requestData (filters, summary) {
            if (this.activeProjectId) {
                let query = {
                  project_id: this.activeProjectId,
                  skip_regionality: 1,
                  v2: 1,
                  is_mobile: this.urlFilters.is_mobile === 0 ? '0' : this.urlFilters.is_mobile,
                  search_engine: this.urlFilters.search_engine,
                  summary,
                  traffic_source: this.urlFilters.traffic_source
                }

                if (this.regionSelected) {
                    query.region_id = this.regionSelected.map(region => region.yandex_region_id).filter(id => id)
                    query.google_region_id = this.regionSelected.map(region => region.google_region_id)
                    query.need_geo = this.regionSelected && this.regionSelected.length ? 1 : undefined
                }

                 if (!this.customSegments) {
                     query.catalog_id = '0'
                 }

                for (let type of TRAFFIC_FILTER_TYPES) {
                    query[type] = this.urlFilters[type]
                    query[`traffic_conditions[${type}]`] = this.urlFilters[`traffic_conditions[${type}]`]
                }

                if (!summary) {
                    let catalog_ids = [...this.urlFilters[this.segmentFilterName]]

                    if (this.urlFilters.control_group) {
                        catalog_ids = [this.urlFilters.control_group, ...catalog_ids]
                    }

                    query = {
                        ...query,
                        [this.segmentFilterName]: catalog_ids,
                        catalog_filter_mode: !this.customSegments ? 'as_is' : undefined,
                        ...filters
                    }
                }

                if (this.dateFilters.comparison) {
                    query.period_one_date_from = this.dateFilters.one.from
                    query.period_one_date_to = this.dateFilters.one.to
                    query.period_two_date_from = this.dateFilters.two.from
                    query.period_two_date_to = this.dateFilters.two.to
                } else {
                    query.date_from = this.dateFilters.one.from
                    query.date_to = this.dateFilters.one.to
                }

                await this.requestDataFunc({
                    query,
                    level: '0'
                })
            }
        },

        getDiffPrc (item) {
            const diff = this.getValue(item[`${this.activeMetric.id}_diff`])
            const one = this.getValue(item[`${this.activeMetric.id}_one`])
            const two = this.getValue(item[`${this.activeMetric.id}_two`])

            return diff === undefined
                ? undefined
                : diff === 0
                    ? 0
                    : diff === one
                        ? 100
                        : roundNumber(diff * 100 / two, 2)
        },

        getValue (val) {
            return typeof val === 'string' ? hmsToSeconds(val) : val
        }
    }
}
