import { render, staticRenderFns } from "./comparisonTable.vue?vue&type=template&id=505ff883&scoped=true"
import script from "./comparisonTable.vue?vue&type=script&lang=js"
export * from "./comparisonTable.vue?vue&type=script&lang=js"
import style0 from "./comparisonTable.vue?vue&type=style&index=0&id=505ff883&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "505ff883",
  null
  
)

export default component.exports