var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-comparison-container',_vm._b({},'v-comparison-container',{
      title: _vm.title,
      segmentFilterUrl: _vm.segmentFilterUrl,
      requestDataFunc: _vm.getUserSegments,
      catalog: _vm.segments,
      segmentTreeCatalog: _vm.segmentTreeCatalog,
      summary: _vm.summary,
      customSegments: true,
      saveCatalogFunc: _vm.saveSegments
    },false))
}
var staticRenderFns = []

export { render, staticRenderFns }