<template>
  <v-comparison-container
      v-bind="{
        title,
        segmentFilterUrl,
        requestDataFunc: getUserSegments,
        catalog: segments,
        segmentTreeCatalog,
        summary,
        customSegments: true,
        saveCatalogFunc: saveSegments
      }"
  />
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import comparisonContainer from '../../components/comparisonContainer'

export default {
  name: 'traction',

  components: {
    'v-comparison-container': comparisonContainer
  },

  data () {
    return {
      title: {
        primary: 'BI',
        secondary: 'Traction'
      },
      segmentFilterUrl: '/tree_user_segments'
    }
  },

  computed: {
    ...mapState({
      segments: state => state.dashboard.traction.segments,
      segmentTreeCatalog: state => state.segments.catalog.dataDFCustom,
      summary: state => state.dashboard.traction.summary
    })
  },

  methods: {
    ...mapActions({
      getUserSegments: 'dashboard/traction/getUserSegments'
    }),

    ...mapMutations({
      saveSegments: 'dashboard/traction/saveSegments'
    })
  }
}
</script>
